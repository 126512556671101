<template>
  <div class="invite-style">
    <div class="outbox">
      <div class="box4">{{$t('text.t204')}}</div>
      <el-card class="box5">
        <div class="center-center">{{showInvite}}</div>
      </el-card>
      <div class="box6 center-center" @click="copy(invite)">{{$t('text.t159')}}</div>
      <el-card class="box-card">
        <div class="box1">{{$t('text.t205')}}</div>
        <div class="box2 between-center">
          <div class="box2-item">{{$t('text.t206')}}</div>
          <div class="box2-item">{{$t('text.t235')}}</div>
          <div class="box2-item">{{$t('text.t240')}}</div>
          <div class="box2-item">{{$t('text.t241')}}</div>
        </div>
        <div>
          <van-list
          v-model="loading"
          :finished="finished"
          :finished-text="$t('text.t39')"
          :offset="10"
          @load="getlist">
            <div class="box3 between-center" v-for="item in list" :key="item.id">
              <div class="box3-item">{{item.username.substr(0,5)}}...{{item.username.substr(item.username.length-4)}}</div>
              <div class="box3-item">{{item.number}}</div>
              <div class="box3-item">{{item.indirect_number}}</div>
              <div class="box3-item">{{item.indirect_pledge}}</div>
            </div>
          </van-list>
        </div>
      </el-card>
    </div>
  </div>
</template>
<script>
export default {
  components: {
  },
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      page:1,
      limit:20,

      invite:'',
      showInvite:''
    };
  },
  computed: {},
  created(){
    
  },
  mounted() {},
  methods: {
    getlist(){
      let arg = {
        page:this.page,
        limit:this.limit
      }
      this.$http.get('/index/invitationList',arg).then(res=>{
        const inv = res.data.href;
				this.invite = inv;
				this.showInvite = inv.substring(0,15) + '......' + inv.substring(inv.length-12);
				const {current_page,data,last_page} = res.data.info;
				if(current_page >= last_page) this.finished = true
				this.page++
				this.loading = false
				this.list = [...this.list,...data]
      })
    },
  },
};
</script>

<style lang="less">
.invite-style {
  .outbox {
    padding: 20px 0;
    .box-card {
      width: 350px;
      border-radius: 20px;
      margin: auto;
      .box1 {
        font-size: 15px;
        font-weight: bold;
        color: #323232;
        margin-bottom: 25px;
        text-align: center;
      }
      .box2 {
        font-size: 12px;
        font-weight: bold;
        color: #323232;
        text-align: center;
        margin-bottom: 5px;
        .box2-item {
          width: 25%;
        }
      }
      .box3 {
        font-size: 9px;
        font-weight: bold;
        color: #323232;
        text-align: center;
        padding: 16px 0;
        border-bottom: 1px solid #e1e1e1;
        .box3-item {
          width: 25%;
        }
      }
    }
    .box4 {
      font-size: 18px;
      font-weight: bold;
      color: #323232;
      margin-bottom: 25px;
      text-align: center;
    }
    .box5 {
      width: 300px;
      border-radius: 10px;
      font-size: 12px;
      font-weight: bold;
      color: #323232;
      margin: 0 auto 25px;
    }
    .box6 {
      font-size: 12px;
      font-weight: bold;
      color: #ffffff;
      width: 300px;
      height: 40px;
      background: #323232;
      border-radius: 10px;
      margin: 0 auto 40px;
    }
  }
}
</style>